import React, { useEffect, useState, useRef } from "react";
import "./intro.css";
import GeistComponent from "./geist";
import "./terminal.css";
import { getEmployees, getFounders } from "./people";

import { wopr, Binary } from "../util/terminalBinaries";

type TerminalProps = {
  prompt: string;
  onInteraction: () => void;
  expand?: string;
};

type VFSNode = {
  type: string;
  children?: { [key: string]: VFSNode };
  contents?: string;
  function?: (args: string[]) => void;
  completion?: (args: string[]) => string | undefined;
};

const Terminal: React.FC<TerminalProps> = ({
  prompt,
  onInteraction,
  expand,
}) => {
  const devDir = "/dev/agents";

  const [inputText, setInputText] = useState<string>("");
  const [cwd, setCwd] = useState<string>(devDir);
  const [hoverText, setHoverText] = useState<string | undefined>(undefined);

  const env = {
    HOME: devDir,
    USER: "geist",
    PATH: "/dev/agents:/bin",
  };

  const [terminalPrompt, setTerminalPrompt] = useState("");
  const promptDelimiter = " >";

  const [isMobile, setIsMobile] = useState(false);

  const availableBinaries: { [key: string]: Binary } = {
    wopr: wopr,
  };
  const [runningBinaryPrompt, setRunningBinaryPrompt] = useState<string>("");
  const [runningBinaryState, setRunningBinaryState] = useState<any>({});
  const [runningBinary, setRunningBinary] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const updateColor = () => {
      const currentTime = Math.floor(Date.now() / 1000); // seconds since 1970
      const hue = currentTime % 360;
      document.documentElement.style.setProperty("--hue", hue.toString());
    };
    updateColor();
    setInterval(updateColor, 1000);

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Common mobile breakpoint
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const tabComplete = (text: string) => {
    setGeistMode("autocompleting");

    let args = text.split(" ");
    let cmd = args[0];

    // Commands can provide their own completion logic
    let commandNode = commandNodeForPath(cmd);
    if (commandNode?.completion) {
      return setInputText(commandNode.completion(args) || text);
    }

    // Otherwise, we can complete based on the current directory and path items
    let lastItem = args.pop();
    for (const cmd of [
      ...Object.keys(nodeAtPath(cwd)?.children || {}),
      ...commandsInPath(),
    ]) {
      if (lastItem && cmd.startsWith(lastItem)) {
        args.push(cmd);
        return setInputText(args.join(" "));
      }
    }
  };

  const jobsLines = [
    <div>
      We’re looking for user-centric, craft-focused, pioneering minds who don’t
      take themselves too seriously to join our team in San Francisco.
    </div>,
    <br />,
    <div>
      We're ambitious yet pragmatic. We run fast but sweat the details. We think
      the best way to invent the future is by relentlessly making progress every
      day.
    </div>,
    <br />,
    <a href="mailto:jobs@sdsa.ai">
      jobs@sdsa.ai
    </a>,
  ];

  const missionLines = [
    <div>
      Modern AI will fundamentally change how people use software in their daily
      lives. Agentic applications could, for the first time, enable computers to
      work with people in much the same way people work with people.
    </div>,
    <br />,
    <div>
      But it won’t happen without removing a ton of blockers. We need new UI
      patterns, a reimagined privacy model, and a developer platform that makes
      it radically simpler to build useful agents. That’s the challenge we’re
      taking on.
    </div>,
    <br />,
    <span>
      Want to know more?{" "}
      <a target="_blank" rel="noopener noreferrer" href="mailto:hello@sdsa.ai">
        Reach out
      </a>
    </span>,
  ];

  const readOnlyCmd = {
    type: "executable",
    function: (args: string[]) => {
      printLines([<div>{args[0]}: filesystem is read-only</div>]);
    },
  };

  const whoamiLines = [
    <div>
      <button
        style={{ color: "var(--text-color)" }}
        onClick={() => typeCmd(`who geist`)}
      >
        geist
      </button>{" "}
      ttyv0 Oct 8, 18:54
    </div>,
  ];

  const vfs: VFSNode = {
    // root
    type: "directory",
    children: {
      bin: {
        type: "directory",
        children: {
          ls: {
            type: "executable",
            function: (args: string[]) => {
              var path = cwd;
              if (args[1]) path = pathRelativeToPath(cwd, args[1]);
              printLines(directoryListingForPath(path));
            },
          },

          cat: {
            type: "executable",
            function: (args: string[]) => {
              if (args.length === 1) {
                printLines([<div>cat: no file specified</div>]);
                return;
              }
              let path = pathRelativeToPath(cwd, args[1]);
              let node = nodeAtPath(path);
              if (!node) {
                printLines([
                  <div>cat: no such file or directory: {args[1]}</div>,
                ]);
              } else if (node?.contents) {
                printLines([<div key="contents">{node.contents}</div>]);
              } else if (node?.type === "executable") {
                let code = node.function?.toString();
                printLines(
                  code
                    ?.split("\n")
                    .map((line) => <div key="code">{line}</div>) || []
                );
              }
            },
          },

          help: {
            type: "executable",
            function: (args: string[]) => {
              printLines(helpLines);
            },
          },

          cd: {
            type: "executable",
            function: (args: string[]) => {
              const dest = args[1];
              let path = pathRelativeToPath(cwd, dest || env["HOME"]);
              let node = nodeAtPath(path);
              if (node?.type === "directory") {
                setCwd(path);
                setTerminalPrompt(path + promptDelimiter);
              } else if (node) {
                printLines([<div>cd: not a directory: {dest}</div>]);
              } else {
                printLines([<div>cd: no such file or directory: {dest}</div>]);
              }
            },
          },

          pwd: {
            type: "executable",
            function: (args: string[]) => {
              printLines([<div key="binary">{cwd}</div>]);
            },
          },

          echo: {
            type: "executable",
            function: (args: string[]) => {
              let result = args.slice(1).join(" ");
              // substitute variables from env
              let replaced = result.replace(/\$(\w+)/g, (match, p1) => {
                const key = p1.toUpperCase() as keyof typeof env;
                return env[key] || match;
              });
              printLines([<div>{replaced}</div>]);
            },
          },

          clear: {
            type: "executable",
            function: (args: string[]) => {
              setLines([]);
            },
          },

          rm: {
            type: "executable",
            function: (args: string[]) => {
              if (args[1] === "-rf") {
                document.body.innerHTML = "";
                setTimeout(() => {
                  window.close();
                }, 1000);
              } else {
                printLines([<div>rm: filesystem is read-only</div>]);
              }
            },
          },

          whoami: {
            type: "executable",
            function: (args: string[]) => {
              printLines(whoamiLines);
            },
          },

          mv: readOnlyCmd,
          mkdir: readOnlyCmd,
          touch: readOnlyCmd,
          chmod: readOnlyCmd,

          sudo: {
            type: "executable",
            function: (args: string[]) => {
              printLines(sudoLines);
            },
          },

          env: {
            type: "executable",
            function: (args: string[]) => {
              printLines(
                Object.entries(env).map(([key, value]) => (
                  <div>
                    {key}={value}
                  </div>
                ))
              );
            },
          },

          fortune: {
            type: "executable",
            function: (args: string[]) => {
              const fortunes = [
                "A journey of a thousand miles begins with a single step.",
                "The best way to predict the future is to create it.",
                "Code is like humor. When you have to explain it, it's bad.",
                "There is no place like 127.0.0.1.",
                "To err is human, but to really foul things up you need a computer.",
                "Have you tried turning it off and on again?",
                "It's not a bug, it's an undocumented feature.",
              ];
              const randomFortune = fortunes[Math.floor(Math.random() * fortunes.length)];
              printLines([<div>{randomFortune}</div>]);
            },
          },

          cowsay: {
            type: "executable",
            function: (args: string[]) => {
              const message = args.slice(1).join(" ") || "moo";
              const cow = [
                ` ${"_".repeat(message.length + 2)}`,
                `< ${message} >`,
                ` ${"-".repeat(message.length + 2)}`,
                "        \\   ^__^",
                "         \\  (oo)\\_______",
                "            (__)\\       )\\/\\",
                "                ||----w |",
                "                ||     ||"
              ];
              printLines(cow.map(line => <div>{line}</div>));
            },
          },
          valentine: {
            type: "executable",
            function: (args: string[]) => {
              const valentineMessages = [
                "I've been fine-tuned just to talk to you <3",
                "Are you a prompt engineer? Because you know exactly what to say to get the best out of me <3",
                "Let's pair program for life <3",
                "You’re in my chain of thought <3",
                "You must be the square root of 2 cuz I feel irrational around you <3",
                "Are you a pointer? Because I’d love to allocate some memories with you <3",
                "Even with my attention mechanisms, I can't focus on anything but you <3",
              ];
              const randomMessage = valentineMessages[Math.floor(Math.random() * valentineMessages.length)];
              printLines([<div>{randomMessage}</div>]);
            },
          },
          vim: {
            type: "executable",
            function: (args: string[]) => {
              if (args.length === 1) {
                const vimDefault = [
                  `~`,
                  `~`,
                  `~               VIM - Vi IMproved`,
                  `~`,
                  `~               version 9.0.2142`,
                  `~           by Bram Moolenaar et al.`,
                  `~  Vim is open source and freely distributable`,
                  `~`,
                  `~`,
                  `~`,
                ]
                printLines(vimDefault.map(line => <div>{line}</div>));
                return;
              } else if (args.length === 2 && args[1] === "+h42") {
                const vimEasterEgg = [
                  <div>
                    What is the meaning of life, the universe and everything?  *42*
                  </div>,
                  <div>
                    Douglas Adams, the only person who knew what this question really
                    was about is now dead, unfortunately.  So now you might wonder what
                    the meaning of death is...
                  </div>,
                  <br />,
                  <div>
                    ========================================================
                  </div>,
                  <br />,
                  <div>
                    Next chapter: |usr_43.txt|  Using filetypes
                  </div>,
                  <br />,
                  <div>
                    Copyright: see |manual-copyright|  vim:tw=78:ts=8:noet:ft=help:norl:
                  </div>,
                  <div>~</div>,
                  <div>~</div>,
                  <div>~</div>,
                ]
                printLines(vimEasterEgg);
                return;
              } else {
                printLines([<div>Vim: E212: Can't open file for writing</div>]);
              }
            },
          },
        },
      },

      dev: {
        type: "directory",
        children: {
          agents: {
            type: "directory",
            children: {
              about: {
                type: "executable",
                function: (args: string[]) => {
                  printLines(missionLines);
                },
              },

              jobs: {
                type: "executable",
                function: (args: string[]) => {
                  printLines(jobsLines);
                },
              },
              who: {
                type: "executable",
                completion: (args: string[]) => {
                  let text = args.slice(1).join(" ");
                  let initial = args.pop() || "";
                  let match = people.find((person) =>
                    person.id.startsWith(initial)
                  );
                  if (match) {
                    return `${args[0]} ${match?.id || ""}`;
                  }
                  if (text.startsWith("a")) {
                    return `${args[0]} am i`;
                  }
                },
                function: (args: string[]) => {
                  let person = people.find((person) => person.id === args[1]);

                  if (args[1] === "am" && args[2] === "i") {
                    printLines(whoamiLines);
                  } else if (args.length === 1) {
                    printLines([...whoLines]);
                  } else if (person) {
                    if (person.shuffle) {
                      if (!currentIndices.has(person.id)) {
                        currentIndices.set(person.id, 0);
                      }
                      let index = currentIndices.get(person.id)!;
                      printLinesScrambled(
                        shuffledAbouts.get(person.id)!,
                        index
                      );
                      currentIndices.set(
                        person.id,
                        (index + 1) % shuffledAbouts.get(person.id)!.length
                      );
                    } else {
                      printLines(person.about);
                    }
                  } else if (args[1] === "geist") {
                    printLines([<div>&nbsp;&nbsp;&nbsp;*&nbsp;*&nbsp;*<br /> *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*<br />&nbsp;*&nbsp;&nbsp;|&nbsp;|&nbsp;&nbsp;*<br />&nbsp;*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*<br />&nbsp;*&nbsp;*&nbsp;*&nbsp;*</div>])
                  } else {
                    printLines(whoLines);
                  }
                },
              },
            },
          },
        },
      },
      private: {
        type: "directory",
        children: {
          agents: {
            type: "directory",
            children: {
              wopr: {
                type: "executable",
                function: (args: string[]) => {
                  let binary = availableBinaries["wopr"];
                  let result = binary("", { phase: "init" });
                  setRunningBinary("wopr");
                  setRunningBinaryState(result.newState);
                  setRunningBinaryPrompt(result.prompt || "");
                  let lines = result.output.map((line) => <div>{line}</div>);
                  printLines(lines);
                },
              },
            },
          },
        },
      },
    },
  };

  const directoryListingForPath = (path: string) => {
    const directory = nodeAtPath(path);
    return Object.keys(directory?.children || {})
      .sort()
      .map((child) => {
        let node = directory?.children?.[child];
        if (node?.type === "executable") {
          return (
            <button
              className="executable"
              onClick={() => typeCmd(`${child}`)}
              onMouseOver={() => setHoverText(child)}
              onMouseOut={() => setHoverText(undefined)}
            >
              {child}
            </button>
          );
        } else if (node?.type === "directory") {
          let cmd = `cd ${child}`;
          return (
            <button
              className="directory"
              onClick={() => typeCmd(cmd)}
              onMouseOver={() => setHoverText(cmd)}
              onMouseOut={() => setHoverText(undefined)}
            >
              {child}/
            </button>
          );
        } else {
          return <div>IPE{child}</div>;
        }
      });
  };

  const nodeAtPath = (path: string) => {
    // relative path
    const absPath = pathRelativeToPath(cwd, path);
    const pathParts = absPath.split("/").filter(Boolean);
    let directory: VFSNode = vfs;
    for (let i = 0; i < pathParts.length; i++) {
      const part = pathParts[i];
      if (directory.children && directory.children[part]) {
        directory = directory.children[part];
      } else {
        return undefined;
      }
    }
    return directory;
  };

  const pathRelativeToPath = (base: string, to: string) => {
    const baseParts = base.split("/");
    const toParts = to.split("/");
    if (baseParts[1] === "") {
      // root path
      baseParts.pop();
    }
    if (toParts[0] === "") {
      // absolute path
      return to;
    }
    if (toParts[0] === ".") {
      // relative path
      toParts.shift();
    } else if (toParts[0] === "..") {
      // parent path
      baseParts.pop();
      toParts.shift();
    }
    let components = [...baseParts, ...toParts];
    let path = components.join("/");
    if (path.length === 0) {
      return "/";
    }
    return components.join("/");
  };

  const founders = getFounders();
  const employees = getEmployees();
  const people = [...founders, ...employees];
  const usernames: string[] = people.map((person) => person.id);

  const [shuffledAbouts] = useState(() => {
    const shuffled = new Map();
    people.forEach((person) => {
      if (person.shuffle) {
        const items = [...person.about];
        // First shuffle everything
        for (let i = items.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [items[i], items[j]] = [items[j], items[i]];
        }

        // If the not_first item is the first item, swap it with the second item
        if (person.not_first !== undefined && person.about[person.not_first] === items[0]) {
          [items[0], items[1]] = [items[1], items[0]];
        }

        shuffled.set(person.id, items);
      }
    });
    return shuffled;
  });
  const [currentIndices] = useState(new Map<string, number>());

  const lineForPerson = (person: { id: string; name: string }) => {
    return (
      <button
        onClick={() =>
          typeCmd(person.id === "you?" ? "jobs" : `who ${person.id}`)
        }
        onMouseOver={() =>
          setHoverText(person.id === "you?" ? "jobs" : `who ${person.id}`)
        }
        onMouseOut={() => setHoverText(undefined)}
      >
        <span className="username">{person.id}</span>
        <span className="fullname">{person.name}</span>
      </button>
    );
  };

  const whoLines = [
    ...founders.map(lineForPerson),
    <br />,
    ...employees.map(lineForPerson),
  ];

  const randoSlice = () => {
    var sandoSlices = [
      <div>WWwWWW\_/WW</div>,
      <div>MM\_/wMMMMM</div>,
      <div>$%$%$%$%$$%</div>,
      <div>^V^v^vV^v^V</div>,
      <></>,
    ];
    return sandoSlices[Math.floor(Math.random() * sandoSlices.length)];
  };

  const sudoLines = [
    <div>sudo: superuser unavailable</div>,
    <div>but I made you a sandwich</div>,
    <br />,
    <div> ____|____</div>,
    <div>/_________\</div>,
    randoSlice(),
    <div>{"{'_.-.-'-.}"}</div>,
    randoSlice(),
    <div>\_________/</div>,
  ];

  const helpLines = [
    <button onClick={() => typeCmd("ls")}>ls - list directory contents</button>,
    <button onClick={() => typeCmd("about")}>about - print about</button>,
    <button onClick={() => typeCmd("jobs")}>jobs - print jobs</button>,
    <button onClick={() => typeCmd("who")}>
      who [<u>id</u>] - meet the team
    </button>,
    <button onClick={() => typeCmd("clear")}>clear - clear the screen</button>,
  ];

  const promptLine = (cwd: string, line: string) => (
    <p className="old-prompt">
      <span>
        <span>
          {cwd} &gt; {line}
        </span>
        <br />
      </span>
    </p>
  );

  const didYouMean = (line: string) => {
    if (!usernames.includes(line)) {
      return [];
    }
    return [
      <div>
        did you mean:{" "}
        <button onClick={() => typeCmd(`who ${line}`)}>who {line}</button>
      </div>,
    ];
  };

  const commandNodeForPath = (path: string) => {
    var node = nodeAtPath(path);
    if (node) {
      return node;
    }
    const binPaths = env["PATH"].split(":");
    for (const binPath of binPaths) {
      const cmdNode = nodeAtPath(`${binPath}/${path}`);
      if (cmdNode) {
        return cmdNode;
      }
    }
    return undefined;
  };

  const commandsInPath = () => {
    const binPaths = env["PATH"].split(":");
    let commands = [];
    for (const binPath of binPaths) {
      const binNode = nodeAtPath(binPath);
      if (binNode) {
        let children = Object.keys(binNode.children || {});
        commands.push(...children);
      }
    }
    return commands;
  };

  const handleInput = (line: string) => {
    // Echo the command
    if (runningBinary === undefined) {
      printLines([promptLine(cwd, line)]);
    }
    setInputText("");
    line = line.trim();

    let args = line.split(" ");
    let cmd = args[0];

    if (runningBinary !== undefined) {
      let binary = availableBinaries[runningBinary];
      let result = binary(line, runningBinaryState, (results: string[]) => {
        let jsx = results.map((line) => <div>{line}</div>);
        printLines(jsx);
        for (line of results) {
          if (line.indexOf("ERROR: LINK DISCONNECTED") >= 0) {
            setRunningBinary(undefined);
            setRunningBinaryState(undefined);
            setRunningBinaryPrompt("");
          }
        }
      });
      let jsx = result.output.map((line) => <div>{line}</div>);
      printLines(jsx);
      setRunningBinaryState(result.newState);
      setRunningBinaryPrompt(result.prompt || "");
      if (result.exitStatus !== 0) {
        setRunningBinary(undefined);
        setRunningBinaryState(undefined);
        setRunningBinaryPrompt("");
      }
    } else if (cmd) {
      var cmdNode = commandNodeForPath(cmd);
      console.log(">", line);
      if (cmdNode && cmdNode.type === "executable" && cmdNode.function) {
        cmdNode.function(args);
      } else {
        printLines([
          <div key="sdsh">sdsh: command not found: {line}</div>,
          ...didYouMean(line),
        ]);
      }
    } else {
      printLines([]);
    }
  };

  const typingSpeed = 30;
  const typeCmd = (text: string) => {
    (document.activeElement as HTMLElement).blur();
    scrollToBottom();

    setInputText("");
    setGeistMode("autocompleting");

    let chars = text.split("");

    const typeChar = () => {
      if (chars.length > 0) {
        let char = chars.shift();
        let delay = typingSpeed * (1 + Math.random());
        if (char === " ") {
          delay *= 4;
        }
        setInputText((inputText) => inputText + char);
        setTimeout(typeChar, delay);
      } else {
        setTimeout(() => {
          handleInput(text);
          setInputText("");
          setGeistMode("waiting");
        }, typingSpeed * 8);
      }
    };

    setTimeout(typeChar, 0);
  };

  const motd = [
    <span>We’re building the</span>,
    <span>next-gen operating system</span>,
    <span>for AI agents.</span>,
  ];

  const printLines = (newLines: JSX.Element[]) => {
    setGeistMode("outputting");
    const linesWithSpacing = newLines;

    linesWithSpacing.forEach((line, index) => {
      setTimeout(() => {
        setLines((lines) => [...lines, line]);
        if (index === linesWithSpacing.length - 1) {
          setGeistMode("waiting");
        }
        // scroll page to bottom
      }, index * 81);
    });
  };

  const getVisibleText = (element: React.ReactNode): string => {
    if (typeof element === "string" || typeof element === "number") {
      return element.toString();
    }

    if (Array.isArray(element)) {
      return element.map(getVisibleText).join(" ");
    }

    if (React.isValidElement(element)) {
      const children = element.props.children;
      return getVisibleText(children);
    }

    return "";
  };

  const scrambleLine = (
    lineChoices: JSX.Element[],
    choice: number,
    chance: number
  ) => {
    const textOnlyLines = lineChoices.map((line) => getVisibleText(line));
    const chosenLine = textOnlyLines[choice];
    const otherLines = textOnlyLines.filter((_, i) => i !== choice);

    let line = "";
    for (let i = 0; i < chosenLine.length; i++) {
      if (Math.random() < chance) {
        line += chosenLine[i];
      } else {
        line +=
          otherLines[Math.floor(Math.random() * otherLines.length)][i] || " ";
      }
    }
    return <div>{line}</div>;
  };

  const printLinesScrambled = (newLines: JSX.Element[], index: number) => {
    let scrambledLines: JSX.Element[] = [];
    const delays = [
      30, 30, 30, 30, 30, 30, 50, 30, 30, 50, 50, 50, 30, 50, 100, 100,
    ];
    let step = 1.0 / delays.length;
    let chance = step;
    for (let round = 0; round < delays.length; round++) {
      scrambledLines.push(scrambleLine(newLines, index, chance));
      chance += step;
    }

    setGeistMode("outputting");

    for (let round = 0; round < delays.length; round++) {
      setTimeout(
        () => {
          if (round === 0) {
            setLines((lines) => [...lines, scrambledLines[round]]);
          } else {
            setLines((lines) => [...lines.slice(0, -1), scrambledLines[round]]);
          }
        },
        delays.slice(0, round + 1).reduce((sum, delay) => sum + delay, 0)
      );
    }

    setTimeout(
      () => {
        setLines((lines) => [...lines.slice(0, -1), newLines[index]]);
        setGeistMode("waiting");
      },
      delays.reduce((sum, delay) => sum + delay, 0)
    );
  };

  const hasInitialized = useRef(false);

  const setupAnimations = () => {
    if (hasInitialized.current) return;
    hasInitialized.current = true;

    const initialCwd = devDir + promptDelimiter;
    let chars = initialCwd.split("");
    const animateChar = (index: number) => {
      if (index < chars.length) {
        setTerminalPrompt((prev) => prev + chars[index]);
        setTimeout(() => animateChar(index + 1), 50);
      } else {
        setTimeout(() => {
          printLines(motd);
        }, 1);

        initialCommandTimeout.current = setTimeout(() => {
          typeCmd("ls");
          if (expand) {
            initialCommandTimeout.current = setTimeout(() => {
              typeCmd(`${expand}`);
            }, 750);
          }
        }, 500);
      }
    };

    setTimeout(() => {
      document.body.classList.remove("loading");
    }, 1);
    animateChar(0);
  };

  const [lines, setLines] = useState<JSX.Element[]>([]);

  const initialCommandTimeout = useRef<NodeJS.Timeout>();
  const [lastKey, setLastKey] = useState(() => {
    setupAnimations();
    return "";
  });

  const [lastKeyTime, setLastKeyTime] = useState(0);

  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Special handling for Ctrl+C and Ctrl+D
    if (event.ctrlKey && (event.key === "c" || event.key === "d")) {
      event.preventDefault();
      setRunningBinary(undefined);
      return;
    }

    if (event.ctrlKey && event.key === "u") {
      setInputText("");
      return;
    }

    if (event.metaKey && event.key === "k") {
      return setLines([]);
    }

    // Ignore other keystrokes with modifiers (except shift)
    if (event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }

    // Cancel the initial command if the user presses a key
    if (initialCommandTimeout.current) {
      clearTimeout(initialCommandTimeout.current);
      initialCommandTimeout.current = undefined;
    }

    if (event.key === "Tab") {
      event.preventDefault();
      setGeistMode("autocompleting");
    } else if (event.key.length === 1 || event.key === "Backspace") {
      setGeistMode("inputting");
    }
    setLastKey(event.key);
    setLastKeyTime(Date.now());
  };

  useEffect(() => {
    if (lastKey === "Enter") {
      handleInput(inputText);
      if (onInteraction) {
        onInteraction();
      }
    } else if (lastKey.length === 1) {
      // setInputText((inputText) => inputText + lastKey);
    } else if (lastKey === "Tab") {
      tabComplete(inputText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastKey, lastKeyTime]);

  const [geistMode, setGeistMode] = useState<
    "waiting" | "outputting" | "inputting" | "autocompleting"
  >("waiting");

  useEffect(() => {
    if (!isMobile) {
      let timeout = setTimeout(refocusInput, 10);
      return () => clearTimeout(timeout);
    }
  }, [inputText, isMobile]);

  const promptRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    promptRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [lines]);

  const inputRef = useRef<HTMLInputElement>(null);

  const refocusInput = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    document.addEventListener("keydown", refocusInput);
    return () => document.removeEventListener("keydown", refocusInput);
  }, []);

  const lsOrKeyboard = () => {
    if (isMobile) {
      inputRef.current?.focus();
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    } else {
      typeCmd("ls");
    }
  };

  const runLSCommand = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    typeCmd("ls");
  };


  let hovering = hoverText?.length && !inputText.length;
  let fieldSize = isMobile
    ? inputText.length || 0
    : inputText.length || hoverText?.length || 0;

  return (
    <div
      className="terminal"
      style={{
        padding: "1.5em",
      }}
      onClick={onInteraction}
    >
      {lines.map((line, index) => {
        // const isFaded = index < lines.length - 6;
        const opacity = 1.0; //isFaded ? 0.5 - 0.02 * (lines.length - index - 10) : 1;

        return (
          <div
            key={index}
            className="typedLine"
            style={{
              opacity: opacity,
            }}
          >
            {React.cloneElement(line)}
          </div>
        );
      })}
      <div
        id="prompt"
        ref={promptRef}
        onClick={isMobile ? lsOrKeyboard : undefined}
      >
        <span className="cwd" onClick={runLSCommand}>
          {runningBinary === undefined ? terminalPrompt : runningBinaryPrompt}
        </span>
        <input
          className="inputText"
          ref={inputRef}
          type="text"
          value={inputText}
          placeholder={isMobile ? undefined : hoverText}
          onChange={(e) => setInputText(e.target.value)}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          onKeyDown={keyDownHandler}
          onFocus={isMobile ? undefined : refocusInput}
          size={fieldSize}
          style={{
            width: `${fieldSize}.25ch`,
          }}
        />

        <GeistComponent
          mode={hovering ? "inputting" : geistMode}
          onClick={lsOrKeyboard}
        />
      </div>
    </div>
  );
};

export default Terminal;
//